




import Vue from 'vue'
import Component from 'vue-class-component'
import AppHeader from "@/modules/genprox/components/AppHeader.vue";
import AppSideBar from "@/modules/genprox/components/AppSideBar.vue";
@Component({
  components: { AppSideBar, AppHeader }
})
export default class CompanyModule extends Vue {
}
